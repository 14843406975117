import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This is my personal website. I'm a Site Reliability Engineer living in Patterson, NY. I use this website to jot down ideas and share some of the things I'm working on. I also use this site to test out new techniques and tools for automation and other SREish things. When I'm not in front of a computer writing code, you'll find me playing around with cameras. I mainly share my work on `}<a parentName="p" {...{
        "href": "https://instagram.com/rickhlx"
      }}>{`my Instagram`}</a>{` so give me a follow!`}</p>
    <p>{`This personal site is inspired by `}<a parentName="p" {...{
        "href": "https://joelhooks.com"
      }}>{`Joel Hook's digital garden`}</a>{`. I love the idea of having a space to hoard information I want to share, and nurturing posts over time.`}</p>
    <p>{`If you'd like to get in touch feel free to `}<a parentName="p" {...{
        "href": "mailto:rickhl@outlook.com"
      }}>{`email`}</a>{` me!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      